import React from "react";
import { KeyboardType, Text, TextInput, View } from "react-native";
import { t } from "react-native-tailwindcss";

interface Props {
  error?: string;
  style?: Record<string, string>;
  editable?: boolean;
  value?: string;
  placeholder?: string;
  onChangeText?: (text: string) => void;
  secureTextEntry?: boolean;
  type?: KeyboardType;
}

const Input = ({
  editable = true,
  type = "default",
  style,
  ...props
}: Props): React.ReactElement => {
  return (
    <View style={styles.wrapper}>
      <TextInput
        style={[
          styles.input,
          props.error && t.borderRed500,
          !editable && styles.disabled,
          style,
        ]}
        keyboardType={type}
        autoCapitalize="none"
        placeholderTextColor={t.fontLight.color}
        editable={editable}
        {...props}
      />
      {props.error && <Text style={styles.errorText}>{props.error}</Text>}
    </View>
  );
};

export default Input;

const styles = {
  wrapper: [t.selfStretch, t.mB2],
  input: [
    t.h11,
    t.border,
    t.selfStretch,
    t.p2,
    t.borderGray500,
    t.roundedLg,
    t.textBase,
    t.textGray700,
  ],
  errorText: [t.mT1, t.textRed500],
  disabled: [{ backgroundColor: "#d3d3d3" }],
};
