import styled from "styled-components/native";

type Props = {
  center?: boolean;
};

export const LargeText = styled.Text<Props>`
  font-size: 20px;
  font-weight: 400;
  padding-bottom: 14px;
  text-align: ${props => (props.center ? "center" : "left")};
`;
