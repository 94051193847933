/* eslint-disable no-nested-ternary */
import * as React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { DefaultTheme, NavigationContainer } from "@react-navigation/native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { Platform, useWindowDimensions } from "react-native";
import { useEffect, useState } from "react";
import { LinkingConfiguration } from "./LinkingConfiguration";
import { DrawerWithLogoutButton } from "./DrawerWithLogoutButton";
import MenuButton from "../components/atoms/MenuButton";
import { appScreens, AppScreensParamList } from "./app-screens";
import { authScreens, AuthScreensParamList } from "./auth-screens";
import { setupScreens, SetupScreensParamList } from "./setup-screens";
import {
  hasWidthAvailableForAppAndSidebar,
  WEB_HEADER_HEIGHT,
  WEB_SIDEBAR_WIDTH,
} from "../web-helper";
import { errorScreens, ErrorScreensParamList } from "./error-screens";

const AuthStack = createStackNavigator<AuthScreensParamList>();
const ErrorStack = createStackNavigator<ErrorScreensParamList>();
const SetupStack = createStackNavigator<SetupScreensParamList>();
const AppDrawer = createDrawerNavigator<AppScreensParamList>();

/** Auth screens
 */
const AuthNavigator = () => {
  const screenOptions = Platform.select({
    web: { headerStyle: { height: WEB_HEADER_HEIGHT }, headerShown: true },
  }) ?? { headerShown: true };

  return (
    <AuthStack.Navigator screenOptions={screenOptions}>
      {authScreens.map(screen => (
        <AuthStack.Screen
          key={screen.name}
          name={screen.name}
          component={screen.component}
          options={screen.options}
        />
      ))}
    </AuthStack.Navigator>
  );
};

/** Error screens
 */
const ErrorNavigator = () => {
  const screenOptions = Platform.select({
    web: { headerStyle: { height: WEB_HEADER_HEIGHT }, headerShown: true },
  }) ?? { headerShown: true };

  return (
    <ErrorStack.Navigator screenOptions={screenOptions}>
      {errorScreens.map(screen => (
        <ErrorStack.Screen
          key={screen.name}
          name={screen.name}
          component={screen.component}
          options={screen.options}
        />
      ))}
    </ErrorStack.Navigator>
  );
};

/** Setup screens
 */
const SetupNavigator = () => {
  const screenOptions = Platform.select({
    web: { headerStyle: { height: WEB_HEADER_HEIGHT }, headerShown: true },
  }) ?? { headerShown: true };

  return (
    <SetupStack.Navigator screenOptions={screenOptions}>
      {setupScreens.map(screen => (
        <SetupStack.Screen
          key={screen.name}
          name={screen.name}
          component={screen.component}
          options={screen.options}
        />
      ))}
    </SetupStack.Navigator>
  );
};

/** App screens
 */
const AppNavigator = () => {
  const { width } = useWindowDimensions();
  const [availableWidth, setAvailableWidth] = useState(width);

  useEffect(() => {
    setAvailableWidth(width);
  }, [width]);

  const headerStyle =
    Platform.select({
      web: { height: WEB_HEADER_HEIGHT },
    }) ?? {};

  const drawerStyle =
    Platform.select({
      web: {
        width: WEB_SIDEBAR_WIDTH,
      },
    }) ?? {};

  return (
    <AppDrawer.Navigator
      screenOptions={({ navigation }) => ({
        headerStyle,
        headerShown: true,
        headerLeft: () =>
          Platform.select({
            ios: <MenuButton navigation={navigation} />,
            android: <MenuButton navigation={navigation} />,
            web:
              // on web, if there is sufficient space on web to fit both the app and navigation on screen (i.e. viewing
              // web app on a laptop / tablet), hide the hamburger menu and show the navigation along the side.
              // otherwise, scale the app size to suit the available width as if it's a mobile device.
              hasWidthAvailableForAppAndSidebar(availableWidth) ? undefined : (
                <MenuButton navigation={navigation} />
              ),
          }),
      })}
      drawerContent={props => (
        <DrawerWithLogoutButton
          {...props}
          items={appScreens.filter(screen => screen.appearsInDrawer)}
        />
      )}
      drawerStyle={drawerStyle}
    >
      {appScreens.map(screen => (
        <AppDrawer.Screen
          key={screen.name}
          name={screen.name}
          component={screen.component}
          options={screen.options}
        />
      ))}
    </AppDrawer.Navigator>
  );
};

const getNavigator = ({
  isAuthenticated,
  isCriticalError,
  onboardingComplete,
  requiresBankReconnection,
}: {
  isAuthenticated: boolean;
  isCriticalError: boolean;
  onboardingComplete: boolean;
  requiresBankReconnection: boolean;
}): JSX.Element => {
  if (isCriticalError) return ErrorNavigator();
  if (!isAuthenticated) return AuthNavigator();
  if (!onboardingComplete || requiresBankReconnection) return SetupNavigator();
  // welcome friend
  return AppNavigator();
};

const RootNavigator = ({
  isAuthenticated,
  isCriticalError,
  onboardingComplete,
  requiresBankReconnection,
}: {
  isAuthenticated: boolean;
  isCriticalError: boolean;
  onboardingComplete: boolean;
  requiresBankReconnection: boolean;
}): React.ReactElement => {
  const navigator = getNavigator({
    isAuthenticated,
    isCriticalError,
    onboardingComplete,
    requiresBankReconnection,
  });
  return navigator;
};

const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: "#ffffff",
  },
};

// If you are not familiar with React Navigation, we recommend going through the
// "Fundamentals" guide: https://reactnavigation.org/docs/getting-started
export const Navigation = ({
  isAuthenticated,
  isCriticalError,
  onboardingComplete,
  requiresBankReconnection,
}: {
  isAuthenticated: boolean;
  isCriticalError: boolean;
  onboardingComplete: boolean;
  requiresBankReconnection: boolean;
}): React.ReactElement => {
  return (
    <NavigationContainer theme={theme} linking={LinkingConfiguration}>
      <RootNavigator
        isAuthenticated={isAuthenticated}
        isCriticalError={isCriticalError}
        onboardingComplete={onboardingComplete}
        requiresBankReconnection={requiresBankReconnection}
      />
    </NavigationContainer>
  );
};
