// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Constants from "expo-constants";
import { Config } from "../dtos/config";

// please refer to the README for more info on environment variable handling

export const appConfig: Config = {
  production: Constants.manifest.extra.production,
  baseUrl: Constants.manifest.extra.baseUrl,
  env: "whitelabel-api",
  tenant: Constants.manifest.extra.tenant,
  primaryColour: Constants.manifest.extra.primaryColour,
  dashboardBackground: Constants.manifest.extra.dashboardBackground,
  testMode: Constants.manifest.extra.testMode === "true",
  firebase: {
    apiKey: Constants.manifest.extra.apiKey,
    authDomain: Constants.manifest.extra.authDomain,
    projectId: Constants.manifest.extra.projectId,
    storageBucket: Constants.manifest.extra.storageBucket,
    messagingSenderId: Constants.manifest.extra.messagingSenderId,
    appId: Constants.manifest.extra.appId,
  },
  features: {
    signupRequiresContributionLimit:
      Constants.manifest.extra.signupRequiresContributionLimit === "true",
    signupRequiresCustomerReference:
      Constants.manifest.extra.signupRequiresCustomerReference === "true",
    signupRequiresWebApp:
      Constants.manifest.extra.signupRequiresWebApp === "true",
  },
};
