import React from "react";
import { Text } from "react-native";
import NumberFormat from "react-number-format";

export const Currency = ({
  cents = 0,
}: {
  cents: number;
}): React.ReactElement => (
  <NumberFormat
    value={cents / 100}
    displayType="text"
    thousandSeparator
    prefix="$"
    renderText={value => <Text>{value}</Text>}
  />
);

export default Currency;
