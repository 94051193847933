import { appConfig } from "../config/config";
import { Institution } from "../dtos/institution";
import { Job, JobStatus, JobType } from "../dtos/job";
import { Transaction } from "../dtos/transaction";
import utils from "../utils";
import api from "./api/api";

export type CreateConnectionRequest = {
  institutionSlug: string;
  username: string;
  password: string;
};

export type GetAccountsResponse = {
  accounts: BankAccount[];
};

export type SyncAccountsResponse = {
  accounts: BankAccount[];
};

export type BankAccount = {
  _id: string;
  id: string; // todo reconcile the fact that this is the bankfeeds id vs the above mongo id, and dif ones are used in dif versions of the api
  externalUserId: string;
  institution: string;
  accountHolderName: string;
  accountNumber: string;
  name: string;
  bsb: string;
  balance: string;
  availableBalance: string;
  accountType: string;
  status: string;
  activeDebitEnabled: boolean;
  activeRoundUpsEnabled: boolean;
};

export const getInstitutions = async (): Promise<Institution[]> => {
  return api.get<Institution[]>(`bank/${appConfig.tenant}/banks`);
};

export const createConnectionJob = async ({
  institutionSlug,
  username,
  password,
}: CreateConnectionRequest): Promise<Job> => {
  return api.post<Job>(`bank/${appConfig.tenant}/banks/user/connect`, {
    institutionSlug,
    username,
    password,
  });
};

// todo convert to queued job structure if this is ever used
export const deleteConnection = async (
  externalUserId: string,
): Promise<void> => {
  return api.delete<void>(
    `bank/${appConfig.tenant}/banks/user/connections/${externalUserId}`,
  );
};

export const getTransactions = async (): Promise<Transaction[]> => {
  return api.get<Transaction[]>(
    `bank/${appConfig.tenant}/banks/user/transactions`,
  );
};

export const getAccounts = async (): Promise<BankAccount[] | null> => {
  const hasAuthToken = await utils.hasAuthToken();
  if (!hasAuthToken) return null;

  return (
    (
      await api.get<GetAccountsResponse>(
        `bank/${appConfig.tenant}/banks/user/accounts`,
      )
    ).accounts ?? []
  );
};

export const syncAccountsJob = async (): Promise<Job> => {
  return api.get<Job>(`bank/${appConfig.tenant}/banks/user/accounts/sync`);
};

export const updateAccount = async (
  accountId: string,
  activeRoundUpsEnabled: boolean,
): Promise<void> => {
  return api.post<void>(
    `bank/${appConfig.tenant}/banks/user/accounts/${accountId}`,
    {
      activeRoundUpsEnabled,
    },
  );
};
