import React from "react";
import { NavigationProp } from "@react-navigation/native";
import { HeaderBackButton } from "@react-navigation/stack";
import { NavigationScreen, Options } from "./shared";
import { CriticalErrorScreen } from "../components/screens/Error/CriticalError/CriticalErrorScreen";
import { HelpScreen } from "../components/screens/Shared/Help/HelpScreen";
import { TermsScreen } from "../components/screens/Shared/Terms/TermsScreen";
import { PrivacyPolicyScreen } from "../components/screens/Shared/PrivacyPolicy/PrivacyPolicyScreen";

export type ErrorScreensParamList = {
  CriticalError: undefined;
  Help: undefined;
  Terms: undefined;
  PrivacyPolicy: undefined;
};

interface ErrorNavigationScreen extends NavigationScreen {
  name: keyof ErrorScreensParamList;
}

export const errorScreens: ErrorNavigationScreen[] = [
  {
    name: "CriticalError",
    component: CriticalErrorScreen,
    options: ({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      navigation,
    }: {
      navigation: NavigationProp<ErrorScreensParamList>;
    }) => ({ headerShown: false, title: "Error" }),
  },
  {
    name: "Help",
    component: HelpScreen,
    options: ({
      navigation,
    }: {
      navigation: NavigationProp<ErrorScreensParamList>;
    }): Options => ({
      headerShown: true,
      headerLeft: () => (
        <HeaderBackButton
          labelVisible={false}
          onPress={() => {
            navigation.goBack();
          }}
        />
      ),
    }),
  },
  {
    name: "Terms",
    component: TermsScreen,
    options: ({
      navigation,
    }: {
      navigation: NavigationProp<ErrorScreensParamList>;
    }): Options => ({
      title: "T&C's",
      headerShown: true,
      headerLeft: () => (
        <HeaderBackButton
          labelVisible={false}
          onPress={() => {
            navigation.goBack();
          }}
        />
      ),
    }),
  },
  {
    name: "PrivacyPolicy",
    component: PrivacyPolicyScreen,
    options: ({
      navigation,
    }: {
      navigation: NavigationProp<ErrorScreensParamList>;
    }): Options => ({
      title: "Privacy Policy",
      headerShown: true,
      headerLeft: () => (
        <HeaderBackButton
          labelVisible={false}
          onPress={() => {
            navigation.goBack();
          }}
        />
      ),
    }),
  },
];
