import { NavigationProp, useNavigation } from "@react-navigation/native";
import Constants from "expo-constants";
import React from "react";
import { View, Text, Platform } from "react-native";
import { t } from "react-native-tailwindcss";
import { appConfig } from "../../config/config";
import { AuthScreensParamList } from "../../navigation/auth-screens";
import { PoweredBySipora } from "../molecules/PoweredBySipora";
import { Footer } from "../molecules/Footer";

type AuthFooterPrompt = {
  promptText: string;
  promptLink: keyof AuthScreensParamList;
  promptLinkText: string;
};

type Props = {
  prompts: AuthFooterPrompt[];
};

export const AuthFooter = ({ prompts }: Props): React.ReactElement => {
  const navigation = useNavigation<NavigationProp<AuthScreensParamList>>();

  const platformAndBuildNumber = Platform.select({
    ios: `IOS ${Constants.manifest?.ios?.buildNumber ?? "undefined"}`,
    android: `Android ${
      Constants.manifest?.android?.versionCode ?? "undefined"
    }`,
    web: `Web ${Constants.manifest?.extra?.webBuildNumber ?? "undefined"}`,
  });
  return (
    <>
      <View style={styles.footer}>
        {prompts.map((p, i) => (
          <Text
            key={i.toString()}
            onPress={() => navigation.navigate(p.promptLink)}
            style={styles.loginLink}
          >
            {p.promptText && `${p.promptText} `}
            <Text style={[styles.loginLink, styles.coloredLink]}>
              {p.promptLinkText}
            </Text>
          </Text>
        ))}

        <Footer />
      </View>

      <PoweredBySipora />

      {!appConfig.production && (
        <>
          <Text style={[styles.footerText, t.mT10]}>
            ### Development mode only ###
          </Text>
          <Text style={[styles.footerText]}>API: {appConfig.baseUrl}</Text>
          <Text style={[styles.footerText]}>
            Build: {platformAndBuildNumber}
          </Text>
        </>
      )}
    </>
  );
};

const styles = {
  loginLink: [t.textCenter, t.mT2],
  footerText: [t.textCenter, t.mT2],
  coloredLink: [t.textBlue400],
  footer: [t.mT20],
};
