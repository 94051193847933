import { NavigationProp } from "@react-navigation/native";
import { DashboardScreen } from "../components/screens/App/Dashboard/DashboardScreen";
import { PayNowScreen } from "../components/screens/App/PayNow/PayNowScreen";
import { FundingAccountScreen } from "../components/screens/App/FundingAccount/FundingAccountScreen";
import { ProfileScreen } from "../components/screens/App/Profile/Profile";
import { RoundupAccountsScreen } from "../components/screens/App/RoundupAccounts/RoundupAccountsScreen";
import { TransactionsScreen } from "../components/screens/App/Transactions/TransactionsScreen";
import { HelpScreen } from "../components/screens/Shared/Help/HelpScreen";
import { PrivacyPolicyScreen } from "../components/screens/Shared/PrivacyPolicy/PrivacyPolicyScreen";
import { TermsScreen } from "../components/screens/Shared/Terms/TermsScreen";
import { NavigationScreen } from "./shared";

export type AppScreensParamList = {
  Dashboard: undefined;
  RoundupAccounts: undefined;
  FundingAccount: undefined;
  Help: undefined;
  PrivacyPolicy: undefined;
  Terms: undefined;
  Profile: undefined;
  Transactions: undefined;
  PayNow: undefined;
};

export interface AppNavigationScreen extends NavigationScreen {
  name: keyof AppScreensParamList;
  appearsInDrawer?: true;
}

export const appScreens: AppNavigationScreen[] = [
  {
    name: "Dashboard",
    component: DashboardScreen,
    appearsInDrawer: true,
    options: ({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      navigation,
    }: {
      navigation: NavigationProp<AppScreensParamList>;
    }) => ({ headerShown: true }),
  },
  {
    name: "RoundupAccounts",
    component: RoundupAccountsScreen,
    appearsInDrawer: true,
    options: ({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      navigation,
    }: {
      navigation: NavigationProp<AppScreensParamList>;
    }) => ({ title: "Round-Ups", headerShown: true }),
  },
  {
    name: "FundingAccount",
    component: FundingAccountScreen,
    appearsInDrawer: true,
    options: ({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      navigation,
    }: {
      navigation: NavigationProp<AppScreensParamList>;
    }) => ({
      title: "Funding Account",
      headerShown: true,
    }),
  },
  {
    name: "Help",
    component: HelpScreen,
    appearsInDrawer: true,
    options: ({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      navigation,
    }: {
      navigation: NavigationProp<AppScreensParamList>;
    }) => ({ headerShown: true }),
  },
  {
    name: "PrivacyPolicy",
    component: PrivacyPolicyScreen,
    appearsInDrawer: true,
    options: ({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      navigation,
    }: {
      navigation: NavigationProp<AppScreensParamList>;
    }) => ({ title: "Privacy Policy", headerShown: true }),
  },
  {
    name: "Terms",
    component: TermsScreen,
    appearsInDrawer: true,
    options: ({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      navigation,
    }: {
      navigation: NavigationProp<AppScreensParamList>;
    }) => ({ title: "T&C's", headerShown: true }),
  },
  {
    name: "Profile",
    component: ProfileScreen,
    appearsInDrawer: true,
    options: ({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      navigation,
    }: {
      navigation: NavigationProp<AppScreensParamList>;
    }) => ({ headerShown: true }),
  },
  {
    name: "Transactions",
    component: TransactionsScreen,
    options: ({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      navigation,
    }: {
      navigation: NavigationProp<AppScreensParamList>;
    }) => ({ headerShown: true }),
  },
  {
    name: "PayNow",
    component: PayNowScreen,
    options: ({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      navigation,
    }: {
      navigation: NavigationProp<AppScreensParamList>;
    }) => ({ title: "Pay Now", headerShown: true }),
  },
];
