import { NavigationProp, useNavigation } from "@react-navigation/native";
import React from "react";
import { ScrollView } from "react-native-gesture-handler";
import { t } from "react-native-tailwindcss";
import { View } from "react-native";
import { useQueryClient } from "react-query";
import { useToast } from "react-native-toast-notifications";
import Button from "../../../atoms/Button";
import { useUser } from "../../../../context/userContext";
import { AppScreensParamList } from "../../../../navigation/app-screens";
import { RoundupAccount } from "./RoundupAccount";
import { ScreenContainer } from "../../../layout/ScreenContainer";
import { MediumText } from "../../../atoms/MediumText";
import { LinkText } from "../../../atoms/LinkText";
import { HCenterStack } from "../../../layout/HStack";
import { updateAccount } from "../../../../services/bankService";
import { QueryKey } from "../../../../services/api/query";
import { useAccounts } from "../../../../context/accountsContext";
import { getErrorMessage } from "../../../../services/api/api";

export const RoundupAccountsScreen = (): React.ReactElement => {
  const toast = useToast();
  const navigation = useNavigation<NavigationProp<AppScreensParamList>>();
  const { user } = useUser();
  const queryClient = useQueryClient();
  const roundupsEnabled = (user?.roundupAmountCents ?? 0) > 0;
  const { accounts } = useAccounts(); //

  const toggleAccount = async (accountId: string, enabled: boolean) => {
    try {
      await updateAccount(accountId, enabled);
      queryClient.invalidateQueries(QueryKey.Accounts);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } catch (error: Error & ApiError) {
      console.log(getErrorMessage(error));
      toast.show("Something went wrong", {
        type: "danger",
      });
    }
  };

  const roundUpsExplainerText = (
    <MediumText>
      Round-ups will be calculated to your configured round-up amount and
      debited every Monday, Wednesday and Friday from your{" "}
      <LinkText onPress={() => navigation.navigate("FundingAccount")}>
        Funding account
      </LinkText>
    </MediumText>
  );

  return (
    <ScreenContainer>
      <ScrollView contentContainerStyle={styles.itemContainer}>
        {!roundupsEnabled && (
          <>
            <MediumText>
              Looks like you haven't enabled round-ups! Turn this on via the{" "}
              <LinkText onPress={() => navigation.navigate("Dashboard")}>
                Dashboard
              </LinkText>{" "}
              to start rounding up your transactions.
            </MediumText>
            {roundUpsExplainerText}
          </>
        )}
        {roundupsEnabled && (
          <>
            <MediumText style={t.mB3}>
              Transactions will be tracked from the enabled accounts below.
            </MediumText>
            {roundUpsExplainerText}
          </>
        )}
        {accounts?.map((account, i) => (
          <RoundupAccount
            account={account}
            key={i.toString()}
            checked={account.activeRoundUpsEnabled}
            updateAccount={toggleAccount}
          />
        ))}

        <HCenterStack style={[t.pT4]}>
          <View style={t.wFull}>
            <Button
              label="Manage My Funding Account"
              onPress={() => navigation.navigate("FundingAccount")}
            />
          </View>
        </HCenterStack>
      </ScrollView>
    </ScreenContainer>
  );
};
const styles = {
  heading: [t.mY8, t.textCenter],
  itemContainer: [t.flex, t.flexCol, t.flex1, { height: "70%" }],
  item: [t.wFull, t.p3, t.mB3],
};
