import { Ionicons } from "@expo/vector-icons";
import React from "react";
import { ActivityIndicator } from "react-native";
import { t } from "react-native-tailwindcss";
import { JobStatus } from "../../dtos/job";
import { UseJobHookInfo } from "../../hooks/useJob";
import { MediumText } from "../atoms/MediumText";
import { HStack } from "../layout/HStack";

type Props = {
  steps: { title: string; context: UseJobHookInfo }[];
  description: string;
};

const StatusIcon = ({ status }: { status?: JobStatus }) => {
  if (status === JobStatus.Success)
    return <Ionicons name="checkmark-sharp" size={24} color="#DB3E52" />;
  if (status === JobStatus.Fail) return <MediumText>X</MediumText>;
  return <ActivityIndicator />;
};

export const JobFeedback = ({
  steps,
  description,
}: Props): React.ReactElement => {
  return (
    <>
      <MediumText style={{ paddingBottom: 30 }}>{description}</MediumText>
      {steps.map(step => (
        <>
          <HStack style={{ marginBottom: 8, justifyContent: "space-between" }}>
            <HStack>
              <Ionicons
                name="lock-closed"
                size={20}
                color="#DB3E52"
                style={[t.mR2, t._mT1]}
              />
              <MediumText>{step.title}</MediumText>
            </HStack>

            <StatusIcon status={step.context.status} />
          </HStack>
        </>
      ))}
    </>
  );
};
