import { Ionicons } from "@expo/vector-icons";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Text, View } from "react-native";
import { t } from "react-native-tailwindcss";
import styled from "styled-components/native";
import Button from "../../../atoms/Button";
import Input from "../../../molecules/Input";
import { Institution } from "../../../../dtos/institution";
import { BankLoginFormData } from "./ConnectBankScreen";
import { appConfig } from "../../../../config/config";
import { LargeText } from "../../../atoms/LargeText";
import { MediumText } from "../../../atoms/MediumText";

const StyledBankHeaderText = styled(LargeText)`
  color: ${appConfig.dashboardBackground};
`;

type Props = {
  institution: Institution;
  onSubmit: (data: BankLoginFormData) => void;
};

export const BankLoginForm = ({
  institution,
  onSubmit,
}: Props): React.ReactElement => {
  // Default BankFeeds test account
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    // dev hack
    defaultValues: appConfig.testMode
      ? {
          username: "12345678",
          password: "TestMyMoney",
        }
      : {
          username: "",
          password: "",
        },
  });

  return (
    <>
      <StyledBankHeaderText center>{institution.name}</StyledBankHeaderText>
      <MediumText>
        Please enter the credentials you use to access your internet banking.
      </MediumText>

      <View>
        {institution.credentials.map((credential, i) => {
          return (
            <Controller
              key={i.toString()}
              name={credential.fieldID}
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ onChange, value }) => (
                <Input
                  error={
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    errors[credential.fieldID] ? "This is required." : undefined
                  }
                  onChangeText={(text: string) => onChange(text)}
                  value={value}
                  placeholder={
                    credential.fieldID === "password"
                      ? "Password"
                      : credential.name
                  }
                  secureTextEntry={credential.fieldID === "password"}
                />
              )}
            />
          );
        })}
        <Button onPress={handleSubmit(onSubmit)} label="Login" />
      </View>

      <View style={styles.lockContainer}>
        <Ionicons
          name="lock-closed"
          size={58}
          color={appConfig.dashboardBackground}
          style={t.mX2}
        />
        <Text>
          Sipora uses bank level security to communicate with your bank in order
          to receive a feed of your transactions to calculate round-ups
        </Text>
      </View>
    </>
  );
};
const styles = {
  lockContainer: [t.flex, t.flexRow, t.p3, t.mT2, t.pR6],
};
