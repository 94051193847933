import { useQuery, UseQueryResult } from "react-query";
import { Institution } from "../../dtos/institution";
import { ApiError } from "../../services/api/api";
import { QueryKey } from "../../services/api/query";
import { getInstitutions } from "../../services/bankService";

export const useInstitutionsQuery = (): UseQueryResult<
  Institution[],
  ApiError
> => {
  return useQuery<Institution[], ApiError>(QueryKey.Institutions, () =>
    getInstitutions(),
  );
};
