import React from "react";
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  FieldValues,
  SubmitHandler,
} from "react-hook-form";
import { Text } from "react-native";
import { CheckBox } from "react-native-elements";
import { t } from "react-native-tailwindcss";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import styled from "styled-components/native";
import Button from "../../../atoms/Button";
import Input from "../../../molecules/Input";
import { authStyles } from "../shared";
import { AuthScreensParamList } from "../../../../navigation/auth-screens";
import { AccountInfoFormData, CustomErrors } from "./RegistrationScreen";
import { VStack } from "../../../layout/VStack";
import { HStack } from "../../../layout/HStack";
import { FormStage } from "./registration-form-stage";
import { appConfig } from "../../../../config/config";

const StyledButtonsContainer = styled(HStack)`
  justify-content: space-between;
`;

const StyledHalfWidthButtonContainer = styled(HStack)`
  width: 49%;
`;

type Props = {
  onSubmit: (data: AccountInfoFormData) => Promise<void>;
  customErrors: CustomErrors;
  setCustomErrors: (e: CustomErrors) => void;
  setFormStage: (f: FormStage) => void;
  termsAgree: boolean;
  setTermsAgree: (v: boolean) => void;
  control: Control<AccountInfoFormData>;
  errors: DeepMap<AccountInfoFormData, FieldError>;
  handleSubmit: <TSubmitFieldValues extends FieldValues = AccountInfoFormData>(
    onValid: SubmitHandler<TSubmitFieldValues>,
  ) => (e?: any) => Promise<void>;
};

export const AccountInfoForm = ({
  onSubmit,
  customErrors,
  setCustomErrors,
  setFormStage,
  termsAgree,
  setTermsAgree,
  control,
  errors,
  handleSubmit,
}: Props): React.ReactElement => {
  const navigation = useNavigation<NavigationProp<AuthScreensParamList>>();

  const runCustomValidation = (data: AccountInfoFormData) => {
    let isValid = true;
    const newCustomErrors: CustomErrors = {
      ...customErrors,
      general: undefined,
    };

    if (
      appConfig.features.signupRequiresContributionLimit &&
      Number.isNaN(data.contributionLimit)
    ) {
      newCustomErrors.contributionLimit = true;
      isValid = false;
    } else {
      newCustomErrors.contributionLimit = false;
    }

    if (
      appConfig.features.signupRequiresCustomerReference &&
      !data.customerReference
    ) {
      newCustomErrors.customerReference = true;
      isValid = false;
    } else {
      newCustomErrors.customerReference = false;
    }

    if (!termsAgree) {
      newCustomErrors.termsAgree = true;
      isValid = false;
    } else {
      newCustomErrors.termsAgree = false;
    }

    if (data.password !== data.confirmPassword) {
      newCustomErrors.passwordMatch = true;
      isValid = false;
    } else {
      newCustomErrors.passwordMatch = false;
    }

    setCustomErrors(newCustomErrors);
    return isValid;
  };

  const validate = (data: AccountInfoFormData): boolean =>
    runCustomValidation(data);

  return (
    <>
      <Controller
        name="email"
        control={control}
        render={({ onChange, value }) => (
          <Input
            error={errors.email ? "This is required." : undefined}
            onChangeText={(text: string) => onChange(text)}
            value={value}
            placeholder="Email"
          />
        )}
        rules={{
          required: true,
        }}
      />
      <Controller
        name="password"
        control={control}
        render={({ onChange, value }) => (
          <Input
            error={errors.password ? "This is required." : undefined}
            onChangeText={(text: string) => onChange(text)}
            value={value}
            placeholder="Password"
            secureTextEntry
          />
        )}
        rules={{
          required: true,
        }}
      />
      <Controller
        name="confirmPassword"
        control={control}
        render={({ onChange, value }) => (
          <Input
            error={errors.confirmPassword ? "This is required." : undefined}
            onChangeText={(text: string) => onChange(text)}
            value={value}
            placeholder="Confirm Password"
            secureTextEntry
          />
        )}
        rules={{
          required: true,
        }}
      />
      {customErrors.passwordMatch && (
        <Text style={authStyles.error}>Must match password.</Text>
      )}

      <CheckBox
        title={
          <Text>
            I agree to the{" "}
            <Text
              style={authStyles.coloredLink}
              onPress={e => {
                navigation.navigate("Terms");
                e.preventDefault();
              }}
            >
              terms and conditions
            </Text>
          </Text>
        }
        checked={termsAgree}
        onPress={() => {
          setTermsAgree(!termsAgree);
        }}
        containerStyle={{
          backgroundColor: "#ffffff",
          borderColor: "#ffffff",
          padding: 0,
          marginTop: 8,
          marginBottom: 16,
          marginLeft: 0,
        }}
        textStyle={[t.textLg, t.fontNormal, t.mL2, t.mR1, t.textGray600]}
      />
      {customErrors.termsAgree && (
        <Text style={[authStyles.error, t._mT2, t.mB3]}>
          Please accept the terms and conditions.
        </Text>
      )}

      <VStack>
        <StyledButtonsContainer>
          <StyledHalfWidthButtonContainer>
            <Button
              onPress={() => setFormStage(FormStage.Personal)}
              colour="gray"
              label="Back"
            />
          </StyledHalfWidthButtonContainer>
          <StyledHalfWidthButtonContainer>
            <Button
              onPress={handleSubmit(data => {
                if (validate(data)) {
                  onSubmit(data);
                }
              })}
              label="Sign Up"
            />
          </StyledHalfWidthButtonContainer>
        </StyledButtonsContainer>
        {customErrors.contributionLimit && (
          <Text style={[authStyles.error, t.mT2]}>
            Something went wrong with your referral link and we can't determine
            your Milton Graham debt amount. Please try following the referral
            link again.
          </Text>
        )}
        {customErrors.customerReference && (
          <Text style={[authStyles.error, t.mT2]}>
            Something went wrong with your referral link and we can't determine
            your Milton Graham debtor specific payment reference number. Please
            try following the referral link again.
          </Text>
        )}
      </VStack>
    </>
  );
};
