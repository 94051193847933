import React from "react";
import styled from "styled-components/native";
import { appConfig } from "../../config/config";

const StyledTouchableOpacity = styled.TouchableOpacity<Pick<Props, "colour">>`
  padding-horizontal: 16px;
  padding-vertical: 12px;
  border-radius: 8px;
  background-color: ${props => props.colour};
  width: 100%;
`;

const StyledText = styled.Text`
  color: #ffffff;
  text-align: center;
`;

type Props = {
  label: string;
  colour?: string;
  disabled?: boolean;
  onPress: () => any;
};

export default function Button({
  label,
  colour = appConfig.primaryColour,
  disabled = false,
  ...props
}: Props): React.ReactElement {
  return (
    <StyledTouchableOpacity
      disabled={disabled}
      activeOpacity={0.8}
      colour={colour}
      {...props}
    >
      <StyledText>{label}</StyledText>
    </StyledTouchableOpacity>
  );
}
