import React from "react";
import { View } from "react-native";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { t } from "react-native-tailwindcss";
import styled from "styled-components/native";
import { ExtraLargeText } from "../../../atoms/ExtraLargeText";
import { ScreenContainer } from "../../../layout/ScreenContainer";
import Button from "../../../atoms/Button";
import { SetupScreensParamList } from "../../../../navigation/setup-screens";
import { MediumText } from "../../../atoms/MediumText";
import { Footer } from "../../../molecules/Footer";
import { HCenterStack } from "../../../layout/HStack";
import { useUser } from "../../../../context/userContext";

const StyledFooterContainer = styled.View`
  margin-top: 50px;
`;

export const WelcomeScreen = (): React.ReactElement => {
  const { user } = useUser();
  const navigation = useNavigation<NavigationProp<SetupScreensParamList>>();
  const headerText = user?.onboardingComplete ? "Reconnect" : "Welcome!";
  const guideText = user?.onboardingComplete
    ? "We'll guide you through reconnecting to your bank."
    : "We'll guide you through the last few steps to finish setting up your account!";
  const description = user?.onboardingComplete
    ? `You'll be asked to connect to your bank using your internet banking credentails, then select a single funding account that we'll debit your total from every Monday, Wednesday and Friday.`
    : `You'll be asked to connect to your bank using your internet banking credentials, select a round-up amount and the accounts you'd like to round up transactions from, then finally select a single funding account that we'll debit your total from every Monday, Wednesday and Friday.`;

  return (
    <ScreenContainer center>
      <HCenterStack>
        <ExtraLargeText center>{headerText}</ExtraLargeText>
      </HCenterStack>

      <MediumText>{guideText}</MediumText>

      <MediumText>{description}</MediumText>

      <View style={styles.buttonContainer}>
        <Button
          label="Begin"
          onPress={() => {
            const nextScreen = !user?.externalUserId
              ? "ConnectBank"
              : "RoundupAccountsSetup";
            navigation.navigate(nextScreen);
          }}
        />
      </View>

      <StyledFooterContainer>
        <Footer />
      </StyledFooterContainer>
    </ScreenContainer>
  );
};

const styles = {
  buttonContainer: [{ width: 200, marginTop: 20 }, t.mLAuto, t.mRAuto],
};
