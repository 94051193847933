import React, { ReactNode, useContext } from "react";
import { useQuery } from "react-query";
import { User } from "../dtos/user";
import { useError } from "../context/errorContext";
import { ApiError } from "../services/api/api";
import { QueryKey } from "../services/api/query";
import { getUser } from "../services/userService";

type UserContext = {
  user?: User | null;
  error?: ApiError | null;
};

const UserContext = React.createContext<UserContext>({} as UserContext);

const UserProvider = ({
  children,
}: {
  children: ReactNode;
}): React.ReactElement => {
  const { setCriticalError } = useError();
  const useUserQuery = useQuery<User | null, ApiError>(
    [QueryKey.User],
    () => getUser(),
    {
      onError: e => setCriticalError(e),
    },
  );
  return (
    <UserContext.Provider
      value={{
        user: useUserQuery.data,
        error: useUserQuery.error,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUser = (): UserContext => useContext(UserContext);
export { UserProvider, useUser };
