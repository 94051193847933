import { ErrorCode } from "../services/api/api";

export enum JobStatus {
  Pending = "pending",
  Success = "success",
  Fail = "fail",
}

export enum JobType {
  Connect = "CreateConnection",
  Sync = "SyncAccounts",
  SetFundingAccount = "SetDebitAccount",
}

export type Job = {
  id: string;
  type: JobType;
  status: JobStatus;
  failReason?: ErrorCode;
};
