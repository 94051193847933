import React, { useState, useEffect } from "react";
import { ScrollView } from "react-native-gesture-handler";
import { t } from "react-native-tailwindcss";
import { useToast } from "react-native-toast-notifications";
import { useQueryClient } from "react-query";
import { ScreenContainer } from "../../../layout/ScreenContainer";
import { FundingAccount } from "./FundingAccount";
import { MediumText } from "../../../atoms/MediumText";
import { ZeptoTerms } from "../../../molecules/ZeptoTerms";
import { ApiError, getErrorMessage } from "../../../../services/api/api";
import { useAccounts } from "../../../../context/accountsContext";
import { LargeText } from "../../../atoms/LargeText";
import { JobFeedback } from "../../../organisms/JobFeedback";
import { setDebitAccountJob } from "../../../../services/userService";
import { useError } from "../../../../context/errorContext";
import { useJob } from "../../../../hooks/useJob";
import { JobStatus } from "../../../../dtos/job";
import { QueryKey } from "../../../../services/api/query";

export const FundingAccountScreen = (): React.ReactElement => {
  const [polling, setPolling] = useState<boolean>(false);
  const setDebitAccountJobContext = useJob(setDebitAccountJob);
  const queryClient = useQueryClient();
  const { setCriticalError } = useError();

  const { accounts } = useAccounts();
  const toast = useToast();

  // set debit account job state
  useEffect(() => {
    // update polling state
    setPolling(setDebitAccountJobContext.status === JobStatus.Pending);
  }, [setDebitAccountJobContext.status]);

  const updateAccount = async (accountId: string) => {
    try {
      await setDebitAccountJobContext.execute(accountId);
      queryClient.invalidateQueries(QueryKey.User);
      queryClient.invalidateQueries(QueryKey.Accounts);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } catch (error: Error & ApiError) {
      console.log(getErrorMessage(error));
      toast.show("Something went wrong", {
        type: "danger",
      });
      setCriticalError(error);
    }
  };

  return (
    <ScreenContainer>
      {polling ? (
        <>
          <LargeText center>Setting Funding Account</LargeText>

          <JobFeedback
            steps={[
              {
                title: "Configuring your debiting agreement",
                context: setDebitAccountJobContext,
              },
            ]}
            description="We are requesting permission to debit your account. This could take a minute."
          />
        </>
      ) : (
        <>
          <ScrollView contentContainerStyle={styles.itemContainer}>
            <MediumText>
              The selected account will have round-up totals debited from.
            </MediumText>
            <ZeptoTerms />
            {accounts?.map((account, i) => (
              // TODO: enum for account types
              <FundingAccount
                valid={["transaction", "savings"].includes(account.accountType)}
                account={account}
                key={i.toString()}
                checked={account.activeDebitEnabled}
                updateAccount={updateAccount}
              />
            ))}
          </ScrollView>
        </>
      )}
    </ScreenContainer>
  );
};
const styles = {
  heading: [t.mY8, t.textCenter],
  itemContainer: [t.flex, t.flexCol, t.flex1, { height: "70%" }],
  item: [t.wFull, t.p3, t.mB3],
};
