import React from "react";
import { appConfig } from "../../../../config/config";

import { ScreenContainer } from "../../../layout/ScreenContainer";
import { MonthlyPayments } from "./MonthlyPayments";
import { RoundupAmounts } from "./RoundupAmounts";
import { TotalAmounts } from "./TotalAmounts";

export const DashboardScreen = (): React.ReactElement => {
  return (
    <ScreenContainer backgroundColor={appConfig.dashboardBackground}>
      <TotalAmounts />
      <RoundupAmounts />
      {/* <DirectDebitAmounts /> */}
      <MonthlyPayments />
    </ScreenContainer>
  );
};
