import React from "react";
import { ScrollView } from "react-native-gesture-handler";
import { ScreenContainer } from "../../../layout/ScreenContainer";
import { LineTitle } from "../../../molecules/LineTitle";
import { SiporaTerms } from "./SiporaTerms";

export const TermsScreen = (): React.ReactElement => {
  return (
    <ScreenContainer>
      <ScrollView>
        <LineTitle title="Sipora Terms Of Use" />
        <SiporaTerms />
      </ScrollView>
    </ScreenContainer>
  );
};
