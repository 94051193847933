import { NavigationProp, useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { t } from "react-native-tailwindcss";
import { useToast } from "react-native-toast-notifications";
import { useQueryClient } from "react-query";
import styled from "styled-components/native";
import { useAccounts } from "../../../../context/accountsContext";
import { useError } from "../../../../context/errorContext";
import { JobStatus } from "../../../../dtos/job";
import { useJob } from "../../../../hooks/useJob";
import { SetupScreensParamList } from "../../../../navigation/setup-screens";
import { getErrorMessage } from "../../../../services/api/api";
import { QueryKey } from "../../../../services/api/query";
import { setDebitAccountJob } from "../../../../services/userService";
import Button from "../../../atoms/Button";
import { ExtraLargeText } from "../../../atoms/ExtraLargeText";
import { LargeText } from "../../../atoms/LargeText";
import { MediumText } from "../../../atoms/MediumText";
import { ScreenContainer } from "../../../layout/ScreenContainer";
import { Footer } from "../../../molecules/Footer";
import { LineTitle } from "../../../molecules/LineTitle";
import { ZeptoTerms } from "../../../molecules/ZeptoTerms";
import { JobFeedback } from "../../../organisms/JobFeedback";
import { FundingAccount } from "../../App/FundingAccount/FundingAccount";

const StyledFundingAccountContentContainer = styled.View`
  margin-top: 30px;
  height: 70%;
`;

const StyledFooterContainer = styled.View`
  margin-top: 30px;
`;

export const FundingAccountSetupScreen = (): React.ReactElement => {
  const [polling, setPolling] = useState<boolean>(false);
  const setDebitAccountJobContext = useJob(setDebitAccountJob);
  const queryClient = useQueryClient();
  const { setCriticalError } = useError();

  const toast = useToast();
  const navigation = useNavigation<NavigationProp<SetupScreensParamList>>();
  const { accounts } = useAccounts();
  const [fundingAccountSelected, setFundingAccountSelected] =
    useState<boolean>(false);

  // set debit account job state
  useEffect(() => {
    // update polling state
    setPolling(setDebitAccountJobContext.status === JobStatus.Pending);
  }, [setDebitAccountJobContext.status]);

  useEffect(() => {
    const hasFundingAccountSet = !!accounts?.find(a => a.activeDebitEnabled);
    setFundingAccountSelected(hasFundingAccountSet);
  }, [accounts]);

  const updateAccount = async (accountId: string) => {
    try {
      await setDebitAccountJobContext.execute(accountId);
      queryClient.invalidateQueries(QueryKey.User);
      queryClient.invalidateQueries(QueryKey.Accounts);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } catch (error: Error & ApiError) {
      console.log(getErrorMessage(error));
      toast.show("Something went wrong", {
        type: "danger",
      });
      setCriticalError(error);
    }
  };

  return (
    <ScreenContainer>
      {polling ? (
        <>
          <LargeText center>Setting Funding Account</LargeText>

          <JobFeedback
            steps={[
              {
                title: "Configuring your debiting agreement",
                context: setDebitAccountJobContext,
              },
            ]}
            description="We are requesting permission to debit your account. This could take a minute."
          />
        </>
      ) : (
        <>
          <ExtraLargeText center>Select Funding Account</ExtraLargeText>

          <StyledFundingAccountContentContainer>
            <MediumText>
              The selected account will have round-up totals debited from every
              Monday, Wednesday and Friday. You can change this later on, or
              turn off round-ups at any time.
            </MediumText>
            <ZeptoTerms />

            <LineTitle title="Select one account" />

            <ScrollView contentContainerStyle={{ height: "100%" }}>
              {accounts?.map((account, i) => (
                <FundingAccount
                  valid={["transaction", "savings"].includes(
                    account.accountType,
                  )}
                  account={account}
                  key={i.toString()}
                  checked={account.activeDebitEnabled}
                  updateAccount={updateAccount}
                />
              ))}
            </ScrollView>
            <View style={styles.buttonContainer}>
              <Button
                label="Next"
                onPress={() => navigation.navigate("FinishOnboarding")}
                disabled={!fundingAccountSelected}
                colour={!fundingAccountSelected ? "gray" : undefined}
              />
            </View>
          </StyledFundingAccountContentContainer>

          <StyledFooterContainer>
            <Footer />
          </StyledFooterContainer>
        </>
      )}
    </ScreenContainer>
  );
};

const styles = {
  buttonContainer: [{ width: 200, marginTop: 20 }, t.mLAuto, t.mRAuto],
};
