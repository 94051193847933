export const WEB_SIDEBAR_WIDTH = 200;
export const DEFAULT_WEB_WIDTH = 420;
export const WEB_HEADER_HEIGHT = 64;
export const REQUIRED_WEB_WIDTH_TO_FIT_APP_AND_SIDEBAR_NAVIGATION =
  WEB_SIDEBAR_WIDTH + DEFAULT_WEB_WIDTH;

export const hasWidthAvailableForAppAndSidebar = (
  availableWebWidth: number,
): boolean => {
  return (
    availableWebWidth >= REQUIRED_WEB_WIDTH_TO_FIT_APP_AND_SIDEBAR_NAVIGATION
  );
};

export const requiresLeftAnchor = (availableWebWidth: number): boolean => {
  return (
    availableWebWidth <=
    REQUIRED_WEB_WIDTH_TO_FIT_APP_AND_SIDEBAR_NAVIGATION + WEB_SIDEBAR_WIDTH
  );
};

export const calculateAppDisplayWidthOnWeb = (
  availableWebWidth: number,
): number => {
  return hasWidthAvailableForAppAndSidebar(availableWebWidth)
    ? DEFAULT_WEB_WIDTH
    : availableWebWidth;
};
