import React from "react";
import Constants from "expo-constants";
import { ScreenContainer } from "../../../layout/ScreenContainer";
import { LineTitle } from "../../../molecules/LineTitle";

export const PrivacyPolicyScreen = (): React.ReactElement => {
  return (
    <ScreenContainer>
      <LineTitle
        title={`${Constants.manifest?.extra?.company} Privacy Policy`}
      />
    </ScreenContainer>
  );
};
