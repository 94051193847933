import React from "react";
import { View, ViewStyle } from "react-native";
import { CheckBox } from "react-native-elements";
import { t } from "react-native-tailwindcss";

const Box = ({
  title = "",
  checked,
  iconRight = false,
  customContainerStyles = {
    backgroundColor: "none",
    borderWidth: 0,
  },
  disabled = false,
  onPress,
}: {
  title?: string;
  checked: boolean;
  iconRight?: boolean;
  customContainerStyles?: ViewStyle;
  disabled?: boolean;
  onPress: () => void;
}): React.ReactElement => {
  return (
    <View style={[{ margin: 0 }]}>
      <CheckBox
        disabled={disabled}
        title={title}
        checked={checked}
        onPress={onPress}
        containerStyle={{
          padding: 0,
          margin: 0,
          ...customContainerStyles,
        }}
        textStyle={[t.mL0, t.mR1, t._mT1, t.fontBold]}
        iconRight={iconRight}
      />
    </View>
  );
};
export default Box;
