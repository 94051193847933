import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Text, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { t } from "react-native-tailwindcss";
import {
  getAuth,
  User as FirebaseUser,
  signInWithEmailAndPassword,
} from "firebase/auth";
import Button from "../../../atoms/Button";
import Input from "../../../molecules/Input";
import ClientLogo from "../../../atoms/ClientLogo";
import { firebaseApp } from "../../../../config/firebase";
import { AuthFooter } from "../../../organisms/AuthFooter";
import { authStyles } from "../shared";
import { HCenterStack } from "../../../layout/HStack";
import { ScreenContainer } from "../../../layout/ScreenContainer";
import { Spinner } from "../../../organisms/Spinner";

type FormData = {
  email: string;
  password: string;
};

type CustomErrors = {
  general?: string;
};

export const LoginScreen = (): React.ReactElement => {
  const [loading, setLoading] = useState(false);
  const [customErrors, setCustomErrors] = useState<CustomErrors>({});

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: { email: "", password: "" },
    // note: this unregisters the form from resetting values on submit or route change
    // note... fix this never clearing when route changes with useFocusEffect()
    // on react-navigation and calling reset() on react-hook-form

    // alternatively. we could add the same animation to the form that the register form has,
    // as that seems to trigger a form reset when route changes due to it being
    // removed entirely from the dom at the beginning of the animation
    shouldUnregister: false,
  });

  const onSubmit = async ({ email, password }: FormData) => {
    setCustomErrors({
      ...customErrors,
      general: undefined,
    });
    setLoading(true);

    try {
      const auth = getAuth(firebaseApp);
      const { user: firebaseUser } = await signInWithEmailAndPassword(
        auth,
        email,
        password,
      );
      // note: firebase auth state changing triggers a refetch on the user object in the authContext.tsx
      // once auth state is valid AND the user object is populated, app.tsx kicks in to redirect to the appropriate app screen
      handleSignInResult(firebaseUser);
    } catch (error: any) {
      setCustomErrors({
        ...customErrors,
        general:
          error?.message ?? "Something went wrong, please contact support",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSignInResult = (firebaseUser: FirebaseUser | null): void => {
    firebaseUser
      ? checkVerification(firebaseUser)
      : showError("Something is wrong with your account");
  };

  const showError = (message: string) => {
    setCustomErrors({
      ...customErrors,
      general: message,
    });
    setLoading(false);
  };

  const checkVerification = (firebaseUser: FirebaseUser): void => {
    if (!firebaseUser.emailVerified) {
      showError(
        "Your email address is not verified, please follow the link in the verification email we sent before trying again.",
      );
    }
  };

  return (
    <ScreenContainer center>
      <KeyboardAwareScrollView
        keyboardShouldPersistTaps="always"
        scrollEnabled={false}
        resetScrollToCoords={{ x: 0, y: 0 }}
        contentContainerStyle={authStyles.scrollView}
        extraScrollHeight={60}
      >
        {loading ? (
          <Spinner />
        ) : (
          <>
            <HCenterStack style={authStyles.imageContainer}>
              <View style={{ width: "50%", height: "100%" }}>
                <ClientLogo />
              </View>
            </HCenterStack>
            <Controller
              name="email"
              control={control}
              render={({ onChange, value }) => (
                <Input
                  error={errors.email ? "This is required." : undefined}
                  onChangeText={(text: string) => onChange(text)}
                  value={value}
                  placeholder="Email"
                />
              )}
              rules={{
                required: true,
              }}
            />
            <Controller
              name="password"
              control={control}
              render={({ onChange, value }) => (
                <Input
                  error={errors.password ? "This is required." : undefined}
                  onChangeText={(text: string) => onChange(text)}
                  value={value}
                  placeholder="Password"
                  secureTextEntry
                />
              )}
              rules={{
                required: true,
              }}
            />
            <Button onPress={handleSubmit(onSubmit)} label="Login" />

            {customErrors.general && (
              <Text style={[authStyles.error, t.mT2]}>
                {customErrors.general}
              </Text>
            )}

            <AuthFooter
              prompts={[
                {
                  promptText: "Don't have an account?",
                  promptLink: "Registration",
                  promptLinkText: "Register",
                },
                {
                  promptText: "Forgot your password?",
                  promptLink: "ForgotPassword",
                  promptLinkText: "Reset it",
                },
              ]}
            />
          </>
        )}
      </KeyboardAwareScrollView>
    </ScreenContainer>
  );
};
