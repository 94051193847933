import React from "react";
import { ActivityIndicator } from "react-native";
import styled from "styled-components/native";
import { VCenterStack } from "../layout/VStack";

const FullScreenCenterContainer = styled(VCenterStack)`
  height: 100%;
`;

export const Spinner = (): React.ReactElement => {
  return (
    <FullScreenCenterContainer>
      <ActivityIndicator />
    </FullScreenCenterContainer>
  );
};
