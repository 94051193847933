import React from "react";
import { Linking, View } from "react-native";
import { LinkText } from "../atoms/LinkText";
import { MediumText } from "../atoms/MediumText";

export const ZeptoTerms = (): React.ReactElement => (
  <>
    <MediumText>
      By selecting a funding account, you are agreeing to the following terms
      and conditions:
    </MediumText>
    <View style={{ paddingBottom: 16 }}>
      <LinkText
        onPress={() => Linking.openURL("https://www.zepto.com.au/ddrsa/")}
      >
        https://www.zepto.com.au/ddrsa/{" "}
      </LinkText>
      <LinkText
        onPress={() => Linking.openURL("https://www.zepto.com.au/ddr/")}
      >
        https://www.zepto.com.au/ddr/{" "}
      </LinkText>
    </View>
  </>
);
