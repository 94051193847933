import React, { useEffect, useState } from "react";
import styled from "styled-components/native";
import { View } from "react-native";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { ScrollView } from "react-native-gesture-handler";
import { t } from "react-native-tailwindcss";
import { useQueryClient } from "react-query";
import { useToast } from "react-native-toast-notifications";
import { ScreenContainer } from "../../../layout/ScreenContainer";
import { ExtraLargeText } from "../../../atoms/ExtraLargeText";
import { Footer } from "../../../molecules/Footer";
import { MediumText } from "../../../atoms/MediumText";
import Box from "../../../atoms/Box";
import { HCenterStack } from "../../../layout/HStack";
import { useUser } from "../../../../context/userContext";
import { SetupScreensParamList } from "../../../../navigation/setup-screens";
import { RoundupAccount } from "../../App/RoundupAccounts/RoundupAccount";
import Button from "../../../atoms/Button";
import { VStack } from "../../../layout/VStack";
import { LineTitle } from "../../../molecules/LineTitle";
import { updateAccount } from "../../../../services/bankService";
import { setRoundupAmount } from "../../../../services/userService";
import { QueryKey } from "../../../../services/api/query";
import { useAccounts } from "../../../../context/accountsContext";
import { getErrorMessage } from "../../../../services/api/api";

const StyledRoundupAccountsContentContainer = styled.View`
  margin-top: 30px;
  height: 70%;
`;

const StyledFooterContainer = styled.View`
  margin-top: 30px;
`;

const StyledRoundupAmountContainer = styled(VStack)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const StyledRoundupAmountSelectorContainer = styled(HCenterStack)`
  width: 100%;
`;

export const RoundupAccountsSetupScreen = (): React.ReactElement => {
  const toast = useToast();
  const navigation = useNavigation<NavigationProp<SetupScreensParamList>>();
  const queryClient = useQueryClient();
  const { user } = useUser();
  const { accounts } = useAccounts();
  const [roundupSetupComplete, setRoundupSetupComplete] =
    useState<boolean>(false);

  const [roundupAmountCents, setRoundupAmountCents] = useState<number>(0);

  useEffect(() => {
    setRoundupAmountCents(user?.roundupAmountCents ?? 100);
  }, [user]);

  useEffect(() => {
    setRoundupAmount(user?.roundupAmountCents ?? 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const hasAtLeastOneRoundupAccountSelected = !!accounts?.find(
      a => a.activeRoundUpsEnabled,
    );

    const hasRoundupAmountSelected = !!roundupAmountCents;
    setRoundupSetupComplete(
      hasAtLeastOneRoundupAccountSelected && hasRoundupAmountSelected,
    );
  }, [accounts, roundupAmountCents]);

  const toggleAccount = async (accountId: string, enabled: boolean) => {
    try {
      await updateAccount(accountId, enabled);
      queryClient.invalidateQueries(QueryKey.Accounts);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } catch (error: Error & ApiError) {
      console.log(getErrorMessage(error));
      toast.show("Something went wrong", {
        type: "danger",
      });
    }
  };

  const setRoundups = async (amount: number): Promise<void> => {
    try {
      await setRoundupAmount(amount);
      queryClient.invalidateQueries(QueryKey.User);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } catch (error: Error & ApiError) {
      console.log(getErrorMessage(error));
      toast.show("Something went wrong", {
        type: "danger",
      });
    }
  };

  return (
    <ScreenContainer>
      <ExtraLargeText center>Setup your Round-Ups</ExtraLargeText>

      <StyledRoundupAccountsContentContainer>
        <MediumText>
          Select a round-up amount and which accounts you would like to enable
          round-ups for.
        </MediumText>
        <MediumText>
          Transactions for enabled accounts will be round up to the nearest
          selected amount, and the total difference debited every Monday,
          Wednesday and Friday from your chosen funding account. You can change
          these later on.
        </MediumText>

        <StyledRoundupAmountContainer>
          <LineTitle title="Amount" />
          <StyledRoundupAmountSelectorContainer>
            {[
              {
                title: "$1",
                value: 100,
              },
              {
                title: "$2",
                value: 200,
              },
              {
                title: "$5",
                value: 500,
              },
            ].map((b, i) => (
              <Box
                title={b.title}
                checked={b.value === roundupAmountCents}
                onPress={() => {
                  setRoundupAmountCents(b.value);
                  setRoundups(b.value);
                }}
                key={i.toString()}
              />
            ))}
          </StyledRoundupAmountSelectorContainer>
        </StyledRoundupAmountContainer>

        <LineTitle title="Accounts" />

        <ScrollView contentContainerStyle={{ height: "100%" }}>
          {accounts?.map(account => (
            <RoundupAccount
              account={account}
              key={account.id}
              checked={account.activeRoundUpsEnabled}
              updateAccount={toggleAccount}
            />
          ))}
        </ScrollView>
        <View style={styles.buttonContainer}>
          <Button
            label="Next"
            onPress={() => navigation.navigate("FundingAccountSetup")}
            disabled={!roundupSetupComplete}
            colour={!roundupSetupComplete ? "gray" : undefined}
          />
        </View>
      </StyledRoundupAccountsContentContainer>

      <StyledFooterContainer>
        <Footer />
      </StyledFooterContainer>
    </ScreenContainer>
  );
};

const styles = {
  buttonContainer: [{ width: 200 }, t.mLAuto, t.mRAuto],
};
