import { AntDesign } from "@expo/vector-icons";
import React from "react";
import { Control, Controller, FieldValues } from "react-hook-form";
import { Text, View } from "react-native";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import { t } from "react-native-tailwindcss";
import Card from "../../../atoms/Card";
import Input from "../../../molecules/Input";
import { appConfig } from "../../../../config/config";
import { Institution } from "../../../../dtos/institution";

type Props = {
  onSelect: (institution: Institution) => void;
  control: Control<FieldValues>;
  filteredInstitutions: Institution[];
};

const BankSearchForm = ({
  onSelect,
  control,
  filteredInstitutions,
}: Props): React.ReactElement => {
  // console.log(filteredInstitutions);
  return (
    <>
      <Controller
        name="search"
        control={control}
        defaultValue=""
        render={({ onChange, value }) => (
          <Card style={[t.wFull]} cornerRadius={0}>
            <Input
              onChangeText={(text: string) => onChange(text)}
              value={value}
              placeholder="Search"
              style={styles.input as unknown as Record<string, string>}
            />
          </Card>
        )}
      />
      <View style={[t.wFull]}>
        <Text style={styles.chooseText}>Select your banking provider</Text>
      </View>
      <ScrollView contentContainerStyle={styles.institutionContainer}>
        {filteredInstitutions?.map((institution: Institution, i) => (
          <TouchableOpacity
            onPress={() => onSelect(institution)}
            containerStyle={styles.institution}
            key={i.toString()}
          >
            <View style={styles.institutionBox}>
              <Text style={styles.text}>{institution.name}</Text>
              <AntDesign
                name="rightcircleo"
                size={15}
                color={appConfig.primaryColour}
              />
            </View>
          </TouchableOpacity>
        ))}
      </ScrollView>
    </>
  );
};

export default BankSearchForm;

const styles = {
  institutionContainer: [t.flexRow, t.flexWrap, t.justifyStart, t.wFull],
  institution: [t.bgWhite, t.mT1, t.wFull],
  input: [t.roundedLg, t.border, t.p2, t.borderGray500],
  text: [{ flex: 5 }, t.mL3],
  institutionBox: [
    t.flexRow,
    t.justifyBetween,
    t.p2,
    t.pY4,
    { borderBottomWidth: 1 },
    t.borderGray200,
  ],
  chooseText: [t.bgGray200, t.p3],
  container: [t.flex1, t.p6, t.bgGray200],
  heading: [t.mY8, t.textCenter],
};
