import {
  NavigationProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { Platform, useWindowDimensions } from "react-native";
import styled from "styled-components/native";
import { getScreens } from "../../navigation/shared";
import {
  calculateAppDisplayWidthOnWeb,
  WEB_HEADER_HEIGHT,
} from "../../web-helper";
import { HCenterStack } from "./HStack";
import { VStack, VCenterStack } from "./VStack";

type ContentContainerProps = {
  center?: boolean;
  platformSpecificHeaderCompatibleHeight: string;
  platformSpecificWidth: string;
};

type Props = {
  children: any;
  center?: boolean;
  backgroundColor?: string;
};

const ContentContainer = styled.View<ContentContainerProps>`
  display: flex;
  flex-direction: column;
  height: ${props => props.platformSpecificHeaderCompatibleHeight};
  width: ${props => props.platformSpecificWidth};
  justify-content: ${props => (props.center ? "center" : "flex-start")};
  padding: 20px;
`;

export const ScreenContainer = (props: Props): React.ReactElement => {
  const { width, height } = useWindowDimensions();
  const [availableWidth, setAvailableWidth] = useState(width);
  const route = useRoute();
  const navigation = useNavigation<NavigationProp<any>>();

  useEffect(() => {
    setAvailableWidth(width);
  }, [width]);

  const platformSpecificWidth =
    Platform.select({
      web: `${calculateAppDisplayWidthOnWeb(availableWidth)}px`,
    }) ?? `${width}px`;

  // this is awful. all we're trying to do is on web, account for the header height when setting the container
  // height so that it doesn't show a scrollbar when the header is showing. but for whatever reason, react navigation doesn't provide
  // a simple api to check if the header is showing or not. it doesn't seem to require this on mobile
  const getWebHeight = () => {
    const myScreen = getScreens().find(s => s.name === route?.name);
    const headerShown = myScreen?.options(navigation).headerShown ?? false;
    return `${height - (headerShown ? WEB_HEADER_HEIGHT : 0)}px`;
  };

  const platformSpecificHeaderCompatibleHeight =
    Platform.select({ web: getWebHeight() }) ?? `${height}px`;

  const backgroundColor = props.backgroundColor ?? "#ffffff";
  const VerticalPositionedContainer = props.center ? VCenterStack : VStack;
  return (
    <VerticalPositionedContainer style={{ backgroundColor }}>
      <HCenterStack>
        <ContentContainer
          platformSpecificWidth={platformSpecificWidth}
          platformSpecificHeaderCompatibleHeight={
            platformSpecificHeaderCompatibleHeight
          }
          center={props.center}
        >
          {props.children}
        </ContentContainer>
      </HCenterStack>
    </VerticalPositionedContainer>
  );
};
