import React from "react";
import { Text, View } from "react-native";
import { t } from "react-native-tailwindcss";
import Box from "../../../atoms/Box";
import Card from "../../../atoms/Card";
import { BankAccount } from "../../../../services/bankService";
import { HStack } from "../../../layout/HStack";
import { VCenterStack } from "../../../layout/VStack";

export const RoundupAccount = ({
  account,
  checked,
  updateAccount,
}: {
  account: BankAccount;
  checked: boolean;
  updateAccount: (accountId: string, enabled: boolean) => void;
}): React.ReactElement => {
  const setRoundUps = async () => {
    updateAccount(account.id, !account.activeRoundUpsEnabled);
  };

  return (
    <HStack style={[t.bgGray100, t.mB1]}>
      <HStack style={{ width: 50, marginLeft: 20 }}>
        <VCenterStack>
          <View style={t.mB2}>
            <Box checked={checked} onPress={() => setRoundUps()} />
          </View>
        </VCenterStack>
      </HStack>
      <Card style={styles.item} key={account.id}>
        <View>
          <Text style={t.mB2}>{account.name}</Text>
          <Text style={t.mB2}>
            Account Type:{" "}
            <Text style={{ textTransform: "capitalize" }}>
              {account.accountType}
            </Text>
          </Text>
          <Text style={t.mB2}>Account Number: {account.accountNumber}</Text>
        </View>
      </Card>
    </HStack>
  );
};

const styles = {
  container: [t.flex1, t.p6, t.bgGray200],
  heading: [t.mY8, t.textCenter],
  // itemContainer: [t.flex, t.flexCol, t.flex1],
  item: [t.wFull, t.p3, t.flex, t.flexRow],
  logo: [t.mR6],
};
