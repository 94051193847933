import React from "react";
import { View } from "react-native";
import SiporaLogo from "../atoms/SiporaLogo";
import { HCenterStack } from "../layout/HStack";
import { LineTitle } from "./LineTitle";

export const PoweredBySipora = (): React.ReactElement => {
  return (
    <>
      <View style={{ paddingTop: 30 }} />
      <LineTitle bold={false} title="Powered by Sipora" />
      <HCenterStack style={{ height: 60 }}>
        <View style={{ width: "50%", height: "100%" }}>
          <SiporaLogo />
        </View>
      </HCenterStack>
    </>
  );
};
